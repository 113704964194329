import React from 'react';
import moment from 'moment';
import InstitutionProfile from '../commons/institution_profile'


const Payslip = React.forwardRef((props, ref) => {
  const { data } = props;

  const styles = {
    container: {
      width: '210mm',
      minHeight: '297mm',
      padding: '20mm',
      fontFamily: 'Helvetica, Arial, sans-serif',
      color: '#231F20', // Wide Options Gray
    },
    header: {
      textAlign: 'center',
      marginBottom: '20px',
    },
    logo: {
      color: '#2976B9', // Wide Options Blue
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    companyInfo: {
      fontSize: '12px',
      margin: '5px 0',
    },
    title: {
      color: '#2976B9', // Wide Options Blue
      fontSize: '18px',
      fontWeight: 'bold',
      marginTop: '20px',
      marginBottom: '20px',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      marginBottom: '20px',
    },
    th: {
      backgroundColor: '#2976B9', // Wide Options Blue
      color: 'white',
      padding: '10px',
      textAlign: 'left',
      border: '1px solid #231F20',
    },
    td: {
      padding: '8px',
      border: '1px solid #231F20',
    },
    strong: {
      color: '#2976B9', // Wide Options Blue
    },
  };

  return (

    <div ref={ref} style={styles.container}>
        <InstitutionProfile/>
      
      
      <h3 style={styles.title}>Monthly Salary Payslip</h3>
      
      <table style={styles.table}>
        <tbody>
          <tr>
            <td style={styles.td}>Employee Code: {data.employee_code}</td>
            <td style={styles.td}>Employee Name: {data.employee_name}</td>
          </tr>
          <tr>
            <td style={styles.td}>Department: {data.department_name || 'N/A'}</td>
            <td style={styles.td}>Designation: {data.designation_name || 'N/A'}</td>
          </tr>
          <tr>
            <td style={styles.td}>Month: {data.month_name}</td>
            <td style={styles.td}>Payment Date: {moment(data.payment_isodt).format('DD/MM/YYYY HH:mm')}</td>
          </tr>
        </tbody>
      </table>

      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>Earnings</th>
            <th style={styles.th}>Deductions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={styles.td}>
              <table style={{width: '100%'}}>
                <tbody>
                  <tr>
                    <td>Gross Pay</td>
                   
                    <td style={{textAlign: 'right'}}>{Number(data.payment_amount).toLocaleString()}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td style={styles.td}>
              <table style={{width: '100%'}}>
                <tbody>
                  <tr>
                    <td>PAYE</td>
                    <td style={{textAlign: 'right'}}>{Number(data.paye).toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td>SHIF</td>
                    <td style={{textAlign: 'right'}}>{Number(data.shif).toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td>NSSF</td>
                    <td style={{textAlign: 'right'}}>{Number(data.nssf).toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td>HOUSING LEVY</td>
                    <td style={{textAlign: 'right'}}>{Number(data.housing_levy).toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td>NITA</td>
                    <td style={{textAlign: 'right'}}>{Number(data.nita).toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td>Other Deductions</td>
                    <td style={{textAlign: 'right'}}>{Number(data.deduction_amount).toLocaleString()}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={styles.td}>
            <strong style={styles.strong}>Gross Earnings: {Number(data.payment_amount).toLocaleString()}</strong>
            </td>
            <td style={styles.td}>
              <strong style={styles.strong}>Total Deductions: {Number(data.total_deductions).toLocaleString()}</strong>
            </td>
          </tr>
        </tbody>
      </table>

      <p><strong style={styles.strong}>NET PAY: {Number(data.net_pay).toLocaleString()}</strong></p>

      <div style={{marginTop: '50px'}}>
        <p>Employee Signature: _________________ Employer Signature: _________________</p>
      </div>
      
      <p style={{marginTop: '20px', fontSize: '0.8em'}}>Printed on: {moment().format('DD MMMM YYYY')}</p>
    </div>
  );
});

export default Payslip;