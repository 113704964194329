import React from 'react'
import { render } from 'react-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { teal,cyan } from '@material-ui/core/colors'
import CssBaseline from "@material-ui/core/CssBaseline";
import App from './App'
import {BrowserRouter as Router,Route,Link,useParams,Redirect} from 'react-router-dom'
import {store,persistor} from './reducers';
import { PersistGate } from 'redux-persist/integration/react'

import {Provider} from 'react-redux'
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2976B9',
      light: '#5290C7',
      dark:  '#1E5C93',
      contrastText: '#fff'
    },
    secondary: {
      main: '#F8962C',
      light: '#FAB05F',
      dark:  '#C67A23',
      contrastText: '#fff'
    },
    type: 'light',
  },
  sidebar:'#1E5C93',
  topNavApp:{
    bg:'#F2F8FC',
    color:'#000000'
  },
  rootApp:{
    bg: 'linear-gradient(to bottom, #2976B9 0%, #F8962C 100%);'
    //background: 'url(/images/BG.jpg) no-repeat center center fixed',  
  }
});

render(
  
  <MuiThemeProvider theme={theme}>
   
    <CssBaseline />
    <Provider store={store}> 
    <PersistGate loading={<h2>Loading....</h2>} persistor={persistor}>
   
    <App />
   
    </PersistGate>

    </Provider>
 
  </MuiThemeProvider>
  
,
  document.getElementById('root')
)