import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { API_URL } from '../../config.json';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import './commons.css'
let InstitutionProfile = ({ authInfo }) => {
    let [institution, institutionSet] = useState(null);
    useEffect(() => {
        getProfile()
    }, [])

    let getProfile = async () => {
        await axios.get(`${API_URL}/api/get-institution`, { headers: { 'auth-token': authInfo.token } }).then(res => {
            institutionSet(res.data)
        });
    }

    return (
        <div className="print-source">
            <html lang="en">
                <head>
                    <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
                    <meta charset="utf-8" />
                    <meta name="description" content="Static &amp; Dynamic Tables" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
                    <title>Profile</title>
                </head>
                <body>

                    <div style={{ width: '100%', marginBottom: '10px' }}>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <tr>
                                <td style={{ width: '30%', verticalAlign: 'middle', padding: '10px' }}>
                                    <img
                                        alt={institution != null ? institution.pro_name : ''}
                                        src={`${API_URL}/${institution != null ? institution.pro_logo : ''}`}
                                        style={{ width: '250px', height: 'auto' }}
                                    />
                                </td>
                                <td style={{ width: '70%', verticalAlign: 'top', textAlign: 'right', padding: '10px' }}>
                                    <p style={{ margin: '0', fontSize: '18px', fontWeight: 'bold' }}>Wideoptions Trading Company Limited</p>
                                    <p style={{ margin: '0' }}>P.O. Box  185 - 60400 Chuka</p>
                                    <p style={{ margin: '0' }}>Tel: 0713138138 , 0700674006</p>
                                    <p style={{ margin: '0' }}>Email: Info@wideoptions.co.ke</p>
                                    <p style={{ margin: '0', color: '#0077be', fontWeight: 'bold' }}>www.wideoptions.co.ke</p>
                                </td>
                            </tr>
                        </table>
                        <div style={{ borderBottom: '2px solid #0077be', marginTop: '5px' }}></div>
                    </div>





                </body>
            </html>
        </div>
    )
}

const mapStateToPops = (state) => {
    return {
        authInfo: state.authInfoReducer
    }
}

export default connect(mapStateToPops, {})(InstitutionProfile);