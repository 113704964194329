import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { currentRouteSet } from '../actions/actions';
import { pathSpliter } from '../lib/functions';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { APP_URL, API_URL } from '../config.json';
import './global.css'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import { Typography } from '@material-ui/core';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import NoteIcon from '@material-ui/icons/Note';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import BuildIcon from '@material-ui/icons/Build';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ShopIcon from '@material-ui/icons/Shop';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const secondaryColor = '#F8962C';
const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    backgroundColor: '#F2F8FC', // Light blue background as per image
  },
  box: {
    color: '#FFFFFF',
    height: '130px',
    margin: '10px',
    background: '#2976B9', // Wide Options Blue
    textAlign: 'center',
    textDecoration: 'none',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: secondaryColor,
    },
  },

  icon: {
    fontSize: '40px',
    color: '#FFFFFF',
  },

  title: {
    color: '#2976B9',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },

  boxTitle: {
    color: '#ffffff',
    fontWeight: 'regular',
    fontSize: '10px',
    marginTop: '10px',
    fontFamily: 'Helvetica, Arial, sans-serif',
  },
  '@global': {
    '.MuiBox-root': {
      paddingBottom: '1px !important',
      paddingTop: '1px'
    },
    '.MuiBox-root p': {
      color: 'white'

    },
    '.MuiBox-root svg': {
      color: 'white',
      fontWidth: 'bold'
    }
  }
}));

const logout = () => {
  sessionStorage.clear();
  window.location.href = `${APP_URL}`
}

const Dashboard = ({ location, currentRoute, currentRouteSet, authInfo }) => {
  useEffect(() => {
    currentRouteSet(pathSpliter(location.pathname, 1))
  }, []);

  const classes = useStyles();
  return (
    <>
      <div className="dashboard-background" >

        
        
        <h2 class="soft-title">WideOptions ERP </h2>




        {/* <h2 class="soft-title-with-mobile" style={{
    fontWeight: 'bold',fontFamily: 'sans-serif'}}>Branch  : ( {authInfo.userInfo.branch_name} ) {authInfo.userInfo.warehouse_name!=null?`Warehouse : (${authInfo.userInfo.warehouse_name})`:''} and  User  : ( {authInfo.userInfo.user_full_name} ) {authInfo.userInfo.warehouse_name!=null?`Warehouse : (${authInfo.userInfo.warehouse_name})`:''} </h2> */}

        {
          currentRoute == undefined ? (
            <div style={{ padding: '10px' }}>
              <div className="modules-box" style={{ marginTop: '65px', }}>


                <div className="module-box" style={{ background: '#0072BC' }}>
                  <Link to="/sales">
                    <Box p={4}  >
                      <ShopIcon style={{ textAlign: 'center', fontSize: '45px', marginTop: '14px' }} /><br />
                      <p className={classes.boxTitle}>Sales</p>
                    </Box>
                  </Link>
                </div>

                <div className="module-box" style={{ background: '#0072BC' }}>
                  <Link to="/service">
                    <Box p={4}  >
                      <EmojiPeopleIcon style={{ textAlign: 'center', fontSize: '45px', marginTop: '14px' }} /><br />
                      <p className={classes.boxTitle}>Service</p>
                    </Box>
                  </Link>
                </div>



                <div className="module-box" style={{ background: '#0072BC' }}>
                  <Link to="/purchase">
                    <Box p={4}  >
                      <EmojiTransportationIcon style={{ textAlign: 'center', fontSize: '45px', marginTop: '14px' }} /><br />
                      <p className={classes.boxTitle}>Purchase</p>
                    </Box>
                  </Link>
                </div>


                <div className="module-box" style={{ background: '#0072BC' }}>
                  <Link to="/production">
                    <Box p={4}  >
                      <HomeWorkIcon style={{ textAlign: 'center', fontSize: '45px', marginTop: '14px' }} /><br />
                      <p className={classes.boxTitle}>Manufacturing</p>
                    </Box>
                  </Link>
                </div>



                <div className="module-box" style={{ background: '#0072BC' }}>
                  <Link to="/stock">
                    <Box p={4}  >
                      <StoreMallDirectoryIcon style={{ textAlign: 'center', fontSize: '45px', marginTop: '14px' }} /><br />
                      <p className={classes.boxTitle}>Inventory</p>
                    </Box>
                  </Link>
                </div>

                <div className="module-box" style={{ background: '#0072BC' }}>
                  <Link to="/accounts">
                    <Box p={4}  >
                      <AccountBalanceIcon style={{ textAlign: 'center', fontSize: '45px', marginTop: '14px' }} /><br />
                      <p className={classes.boxTitle}>Finance</p>
                    </Box>
                  </Link>
                </div>



                <div className="module-box" style={{ background: '#0072BC' }}>
                  <Link to="/hrpayroll">
                    <Box p={4}  >
                      <SupervisorAccountIcon style={{ textAlign: 'center', fontSize: '45px', marginTop: '14px' }} /><br />
                      <p className={classes.boxTitle}>HR & Payroll</p>
                    </Box>
                  </Link>
                </div>

                <div className="module-box" style={{ background: '#0072BC' }}>
                  <Link to="/reports">
                    <Box p={4}  >
                      <NoteIcon style={{ textAlign: 'center', fontSize: '45px', marginTop: '14px' }} /><br />
                      <p className={classes.boxTitle}>Reports</p>
                    </Box>
                  </Link>
                </div>


                <div className="module-box" style={{ background: '#0072BC' }}>
                  <Link to="/administration">
                    <Box p={4}  >
                      <AdminPanelSettingsIcon style={{ textAlign: 'center', fontSize: '45px', marginTop: '14px' }} /><br />
                      <p className={classes.boxTitle}>Administration </p>
                    </Box>
                  </Link>
                </div>


              </div>
            </div>
          ) : ''
        }

      </div>
      
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    currentRoute: state.returnReducer,
    authInfo: state.authInfoReducer
  }
}
export default connect(mapStateToProps, { currentRouteSet })(Dashboard)